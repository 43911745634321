<template>
	<div class="trip-cancel">
		<div class="trip-cancel__column trip-cancel__column--first">
			<h2 class="section-title">Отменить бронирование</h2>

			<div class="cancel-rules">
				<h3 class="cancel-rules__title">Правила отмены бронирования</h3>
				<ul class="cancel-rules__list">
					<li class="cancel-rules__item">
            Бесплатная отмена бронирования возможна до {{ freeCancelationDate }}
					</li>
          <li
            class="cancel-rules__item"
            v-if="booking?.order?.detail?.cancellation_data?.days
            && booking?.order?.detail?.cancellation_data?.percentage"
          >
            После {{ freeCancelationDate }} процент неустойки за отмену брони
            составляет {{ booking?.order?.detail?.cancellation_data?.percentage }}%.
          </li>
				</ul>
			</div>

<!--			<div class="cancel-specialoffers">-->
<!--				<h3 class="cancel-specialoffers__title">-->
<!--					Специальные предложения от хозяина-->
<!--				</h3>-->
<!--				<ul class="cancel-specialoffers__list">-->
<!--					<li class="cancel-specialoffers__item">-->
<!--						<p>Первое заселение гостя</p>-->
<!--						<p>-->
<!--							Статический текст из объявления о специальном предложении или-->
<!--							скидка в % и % отображается тут в виде текста-->
<!--						</p>-->
<!--					</li>-->
<!--					<li class="cancel-specialoffers__item">-->
<!--						<p>Определенные даты</p>-->
<!--						<p>Статический текст из объявления о специальном предложении</p>-->
<!--					</li>-->
<!--				</ul>-->
<!--			</div>-->

			<!-- <p class="cancel-advice">
				Попробуйте обсудить с гостем иные условия бронирования, может быть вы
				согласитесь на другие условия бронирования . . .
			</p> -->

			<!-- <router-link to="/chat" class="btn cancel__chat-btn">
				Написать хозяину
			</router-link> -->

			<div class="cancel-reasons">
				<h2 class="cancel-reasons__title">
					Укажите причину отклонения заявки на бронирование
				</h2>
				<p class="cancel-reasons__subtitle">Необязательные поля</p>

				<CancelReason
					:content="reasons"
					buttonText="Отменить бронирование"
					buttonStyle="red-border"
					@update="submit"
				/>
			</div>
		</div>

<!--		<div-->
<!--			class="trip-cancel__column trip-cancel__column&#45;&#45;second trip-cancel-rules"-->
<!--		>-->
<!--			<h2 class="section-title">Правила отмены бронирования</h2>-->
<!--			<div class="trip-cancel-rules__wrapper">-->
<!--				<p>-->
<!--					Каждый хозяин вправе устанавливать свои. Правила отмены бронирования.-->
<!--					Внимательно ознакомьтесь с ними перед бронированием, потому что-->
<!--					некоторые хозяева могут взимать неустойку за отмену Вами бронирования.-->
<!--				</p>-->

<!--				<p>-->
<!--					Пример: <br />-->
<!--					Хозяин установил возможность бесплатной отмены бронирования в течение-->
<!--					24 часов с момента оплаты и 50% неустойку за отмену бронирования по-->
<!--					прошествии 24 часов.-->
<!--				</p>-->
<!--				<p class="trip-cancel-rules__sub-text">-->
<!--					В случае “Бесплатной отмены” оплаченная сумма возвращается в течение-->
<!--					72 часов с момента отмены бронирования. Если хозяин выбирает удержание-->
<!--					неустойки в случае отмены Вами бронирования, то сумма возврата = сумма-->
<!--					за проживание(без дополнительных услуг) - неустойка.-->
<!--				</p>-->
<!--			</div>-->
<!--		</div>-->
	</div>
</template>

<script>
import modals from '@/constants/modals'
import CancelReason from '@/components/CancelReasonBlock'
import { getDateInFormat } from "@/utils/dateTime";

export default {
	components: { CancelReason },
  data() {
    return {
      booking: {},
    }
  },
  computed: {
    reasons() {
      return this.$store.getters.guestRejectTypes
    },
    freeCancelationDate() {
      let freeCancelationDate = null
      if (this.booking.order?.detail?.cancellation_data) {
        const freeDays = this.booking.order.detail.cancellation_data.days
        freeCancelationDate = new Date(this.booking.date_from);
        freeCancelationDate.setDate(freeCancelationDate.getDate() - freeDays)
      }
      return freeCancelationDate ? getDateInFormat(freeCancelationDate) : null
    }
  },
	async created() {
		await this.$store.dispatch('getRejectAndComplaint')

    const response = await this.$api.reserve.getReserve({
      reserve_id: this.$route.params.id
    })

    if (response?.status) {
      this.booking = { ...response.response }
    }
	},
	methods: {
		bookingCancellation(props) {
			this.$store.commit('showModal', {
				name: modals.BOOKING_CANCELLATION,
				props,
				isShow: true
			})
		},
		async submit({ form, message }) {
			const response = await this.$api.reserve.cancelBooking({
				reason_code: form,
				message: message,
				reserve_id: this.$route.params.id
			})

			if (!response || !response.status) {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Ошибка отмены бронивания! Обратитесь к администратору.'
				})

				return
			}

			this.$router.push('/profile/trips/upcoming')

			this.bookingCancellation({
				step: 2
			})
		}
	}
}
</script>

<style lang="sass">
.trip-cancel
  position: relative
  display: flex
  width: 100%

  .section-title
    margin-bottom: 30px

  &__column
    &--first
      max-width: 350px
      margin-right: 110px
      margin-bottom: 30px

    &--second
      width: 50%

  .cancel-rules
    padding: 0

    &__title
      margin-bottom: 15px

      font-size: 16px
      font-weight: 600
      line-height: 16px

    &__list
      padding-left: 10px
      margin-bottom: 15px

      list-style: none

    &__item
      position: relative

      font-size: 14px
      line-height: 16px

      &:not(:last-child)
        margin-bottom: 20px

      &:before
        content: ''
        position: absolute
        left: -10px
        top: 6px

        width: 6px
        height: 6px

        background-color: $green
        border-radius: 1px

  .cancel-specialoffers
    margin-left: -20px
    margin-bottom: 25px
    padding: 20px

    background: #F5F5F5
    border-radius: 12px

    &__title
      margin: 0
      margin-bottom: 15px

      font-size: 16px
      font-weight: 600
      line-height: 16px

    &__list
      list-style: none
      padding-left: 10px
      margin-bottom: 0px

    &__item
      position: relative
      font-size: 14px
      line-height: 16px

      &:not(:last-child)
        margin-bottom: 10px

      &:before
        content: ''
        position: absolute
        left: -10px
        top: 32px

        width: 6px
        height: 6px

        background-color: $green
        border-radius: 1px

      p
        margin: 0

        &:first-child
          margin-left: -10px
          margin-bottom: 5px

          font-size: 15px
          line-height: 22px
          color: #3B3F47

  // &-advice
  //   font-size: 16px
  //   font-weight: 600
  //   line-height: 16px
  //   margin-bottom: 15px
  // &-advice
  //   line-height: 20px
  //   padding: 0 20px
  //   margin-bottom: 13px

  .cancel-reasons
    padding: 0

    &__title
      margin-bottom: 18px

      font-size: 18px
      line-height: 20px

    &__subtitle
      font-size: 14px
      line-height: 14px
      color: #CBCBCB

    &__list
      margin-bottom: 36px
      padding-left: 0

      list-style: none

      background-color: #FFFFFF
      border-radius: 10px
      box-shadow: 0px 0px 17px -6px lightgrey

    &__item
      display: flex
      justify-content: space-between
      align-items: center
      padding: 14px 10px 14px 15px

      &:not(:last-child)
        border-bottom: 0.5px solid #E2E2E2

    &__item-text
      margin-bottom: 0

    &__item-select
      position: relative

      padding: 0

      &:before
        content: url('~@/assets/images/svg/arrow-down.svg')
        position: absolute
        right: 19px

      .select__wrapper
        position: relative
        width: 100%

      .select__head
        display: block
        padding: 14px 10px 14px 15px
        z-index: 4

      .select__textarea
        position: absolute
        top: 35px

        width: 100%
        height: 180px
        padding: 30px 14px 14px

        background: #FFFFFF
        border-radius: 0px 0px 12px 12px
        box-shadow: 0px 12px 15px rgba(95,83,80, 0.1)
        z-index: 2

        textarea
          display: block
          width: 100%
          height: 110px
          padding: 10px
          margin-bottom: 10px
          border: 1px solid #E2E2E2
          box-sizing: border-box
          border-radius: 12px
          caret-color: #1AC386
          &:focus
            outline: none

        button
          background: transparent
          border: none
          font-weight: 500
          font-size: 14px
          line-height: 16px
          color: #1AC386

  // &-btn
  //   width: 310px
  //   height: 60px
  //   background: #fff
  //   border: 1px solid #E93030
  //   color: #E93030
  //   bottom: 20px
  //   &:hover
  //     background: #E93030
  //     color: #fff

  .trip-cancel-rules
    &__wrapper
      font-size: 16px
      line-height: 20px

    &__sub-text
      margin-top: 30px

      font-size: 14px
      line-height: 18px
      color: #CBCBCB

@media screen and (max-width: 1440px)
  .trip-cancel
    &__column--first
      margin-right: 40px

@media screen and (max-width: 1024px)
  .trip-cancel
    &__column--first
      margin-right: 20px

@media screen and (max-width: 912px)
  .trip-cancel
    flex-wrap: wrap

    &__column
      &--first
        margin-right: 0
        width: 100%
        max-width: unset

      &--second
        width: 100%

@media screen and (max-width: 800px)
  .trip-cancel
    .cancel-btn
      width: 100%
</style>
